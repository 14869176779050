.error-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.error-text {
    font-size: 7vh;
    color: #f1f1f1;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
    
}

.error-button {
    font-size: 2vh;
    background-color: black;
    padding: 1vh;
    box-shadow: rgba(255, 255, 255, 0.25) 0px 50px 100px -20px, rgba(255, 255, 255, 0.3) 0px 30px 60px -30px, rgba(255, 255, 255, 0.35) 0px -2px 6px 0px inset;
    
}

.error-button:hover {
    transform: scale(1.1);
    transition: 0.5s;
}