.bio-1, .bio-2, .bio-3{
    color:aliceblue;
    font-size: 2.5vh;
    max-width: 50vw;
    margin: 90vh auto 2vh auto;
    text-align: center;
    transition: 0.6s ease-out;
    opacity: 0;
    transform: translateX(-8vw);
    filter: blur(8px);
}

.bio-2{
    transform: translateX(8vw);
}

.bio-greentext {
    color: var(--main-light-green);
}

.animate-bio {
    animation: animate-bio 0.5s ease-out forwards;
}

@keyframes animate-bio {
    to {
        transform: translateX(0);
        transform: translateY(0);
        opacity: 1;
        filter: blur(0);
    }
}


@media (max-width: 600px) {
    .bio-1, .bio-2, .bio-3{
        transform: translateY(-8vw);
        max-width: 90vw;
        margin: 70vh auto 2vh auto;
    }
    
}

