.roller-text {
    width: fit-content;
    margin: auto;
    margin-top: 12vh;
    color:var(--main-white);
    font-family: "Shadows Into Light";
    font-size: 28px;
    transition: .3s ease-in-out;
}

.roller-text:hover {
    transform: scale(1.1);
    color: var(--main-light-green);
}

.roller-container {
    margin-bottom: 5vh;
    margin-inline: 36vw;
    margin-top: 2vh;
    padding-block: 10px;
    display: flex;
    justify-content: center;
    font-size: 30px;
    overflow: hidden;
    -webkit-mask-image: linear-gradient(90deg, transparent, #050505 40%, #000000 60%, transparent);
}

.roller-items {
    animation: scroll 20s linear infinite;
    display: flex;
}

.roller-item {
    margin-left: 6vw;
    transition: 0.4s ease-out;
}

.roller-item:hover {
    transform: scale(1.2);
    cursor: pointer;
}

.roller-arrow {
    position: absolute;
    right: 1%;
    transform: rotate(180deg);
    margin-right: 5vw;
}


@keyframes scroll {
    0% {
        transform: translateX(50%);
    }
    100% {
        transform: translateX(-50%);
    }
}

@media (max-width: 600px) {
    .roller-container {
        margin-top: 1vh;
        margin-bottom: 2vh;
        margin-inline: 13vw;
    }
    .roller-item {
        margin-left: 15vw;
    }
    .roller-arrow {
        margin-right: 0px;
    }
    .roller-text{
        margin-top: 16vh;
    }
    
}

a:link, a:visited {
    color: white;
    background-color: transparent;
    text-decoration: none;
    transition: 0.5s ease;
    
}

a:hover {
  color: var(--main-light-green);
}


