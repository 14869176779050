:root {
  --main-black: #0d0e0f;
  --main-black-opacity70: rgba(7, 8, 8, 0.7);
  --main-white: rgb(182, 182, 182);
  --main-light-green: rgb(122, 253, 210);
}

body {
  background-color: linear-gradient(black);
  background-image: url(resources/bg/338_v2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  /*background-attachment*/
  cursor: default;
  text-align: center;
  margin: 0;
  font-family: "Playfair Display", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* custom scrollbar */
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background: var(--main-black-opacity70);
}
::-webkit-scrollbar-thumb {
  background: var(--main-white);
  border-radius: 6px;
}
::-webkit-scrollbar-thumb:hover {
  background: var(--main-light-green); 
}

