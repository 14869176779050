.photo-container {
    margin: 0 auto;
    margin-inline: 10vw;
    margin-top: 90vh;
    margin-bottom: 10vh;
    height: 40vh;
    width: 70vw;
    display: flex;
    justify-content: center;
    overflow: hidden;
    padding: 20px;
    opacity: 0;
}


.photo-profile {
    max-height: 100%;
    transition: 1.2s ease-out;
    border-radius: 8px;
    
}

.photo-thatsme {
    margin-left: 0vw;
    width: 15%;
    max-width: 150px;
    opacity: 90%;
    transition: 1.2s ease-out;
    height: fit-content;
    margin-top: 10vh;
}

.photo-container-animation {
    animation: animate-photo 2s ease-out forwards;
}

@keyframes animate-photo {
    to {
        transform: scale(1.2) rotate(-1deg);
        opacity: 1;
    }
}


.photo-profile:hover {
    transform: scale(1.1) rotate(5deg);
}

.photo-thatsme:hover {
    transform: scale(1.1) rotate(-6deg);
}

@media (max-width: 600px) {
    .photo-container {
        height: 35vh;
        margin: 0 auto;
        margin-top: 50vh;
        display: flex;
        justify-content: center;
    }
    .photo-thatsme{
        margin-left: 0;
        margin-top: 6vh;
        border-radius: 8px;
        max-height: 50%;
    }
    .photo-profile {
        max-height: 50vh;
        box-shadow: none;
        border-radius: 8px;
        
    }
}